<template>
  <div
    id="app"
    class="w-full h-full flex items-center justify-center"
  >
    <form
      id="form"
      class="w-full max-w-xs shadow rounded px-6 pt-6 pb-8"
      @submit.prevent="onLogin"
    >
      <label class="block mb-4">
        <span
          class="label block"
        >ログインID</span>
        <input
          v-model="account"
          type="text"
          class="input"
          required
        />
      </label>

      <label class="block">
        <span
          class="label block"
        >パスワード</span>
        <input
          v-model="password"
          type="password"
          class="input"
          required
        />
      </label>

      <div class="mt-3 h-10 text-red-500 text-xs italic">
        <span
          v-for="(msg, idx) in errors"
          :key="idx"
          class="block"
        >{{msg}}</span>
      </div>

      <div class="mt-3 flex items-center">
        <button
          class="button ml-auto"
          type="submit"
          :disabled="logining"
        >ログイン</button>
      </div>
    </form>
  </div>
</template>

<script>
import { HTTP } from '@/lib/code'

export default {
  data () {
    return {
      account: '',
      password: '',
      logining: false,
      errors: []
    }
  },
  mounted () {
    // この画面を表示したら、セッション情報を削除する
    sessionStorage.clear()
  },
  methods: {
    //
    // ログインボタン
    //
    onLogin () {
      this.logining = true
      this.errors = []

      this.$api.post('/login', {
        account: this.account,
        password: this.password
      })
        .then(resp => {
          if (resp.status === HTTP.OK) {
            this.onLoginOk(resp.data)
          } else {
            this.onLoginFailed(resp.status)
          }
        })
        .catch(err => {
          this.onLoginFailed(HTTP.InternalSystemError)
          console.error(err)
          this.$api.errorReport(err)
        })
        .finally(() => {
          this.logining = false
        })
    },

    //
    // ログイン成功
    //
    onLoginOk (data) {
      // ユーザ情報を保存する
      sessionStorage.setItem('ccms:user', JSON.stringify(data))

      const query = this.getQuery()

      // メイン画面に移動する
      location.href = query.redirectto || '/cm/'
    },

    //
    // ログイン失敗
    //
    onLoginFailed (status) {
      switch (status) {
        case HTTP.NotFound:
          this.errors = [
            'ログインに失敗しました。',
            'ログインIDまたはパスワードをご確認ください。'
          ]
          break
        case HTTP.Locked:
          this.errors = [
            'ログインIDがロックされています。',
            'システム管理者にお問合せ下さい。'
          ]
          break
        case HTTP.InternalSystemError:
          this.errors = [
            'システムに異常が発生しました。',
            'システム管理者にお問合せ下さい。'
          ]
          break
        default:
          this.errors = [
            `サーバとの通信に失敗しました。ERROR: ${status}`,
            'システム管理者にお問合せ下さい。'
          ]
      }
    },

    //
    // クエリ部分をオブジェクト化する
    //
    getQuery () {
      // URL のクエリ部分を取得する
      const q = location.search
      if (!q) {
        return {}
      }

      // クエリ部分を分解する
      return q.slice(1).split('&').reduce((query, item) => {
        const kv = item.split('=').map(decodeURIComponent)
        query[kv[0]] = (kv.length === 1) ? true : kv[1]
        return query
      }, {})
    }
  }
}
</script>
